import axios from 'axios';

const api = axios.create({
  baseURL: 'https://portfolio-chat.azurewebsites.net',
});

// const api = axios.create({
//   baseURL: 'http://localhost:8080',
// });

export const createThread = async (): Promise<string> => {
  try {
    const response = await api.post('/create-thread');
    return response.data.threadId;
  } catch (error: any) {
    console.error(`Error creating thread: ${error.message}`);
    throw error;
  }
};

export const sendMessage = async (threadId: string, messageContent: string): Promise<string> => {
  try {
    const response = await api.post('/send-message', { threadId, messageContent });
    return response.data.response;
  } catch (error: any) {
    console.error(`Error sending message: ${error.message}`);
    throw error;
  }
};

export const generateSuggestions = async (): Promise<string[]> => {
  try {
    const response = await api.get('/get-suggestions');
    return response.data.response.questions;
  } catch (error: any) {
    console.error(`Error sending message: ${error.message}`);
    throw error;
  }
};