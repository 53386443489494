import React, {ChangeEvent, useEffect, useState} from 'react';
import './SearchBar.css';
import {ThreeCircles} from 'react-loader-spinner';
import {createThread, sendMessage, generateSuggestions} from '../../Services/ChatService';
import Markdown from 'react-markdown'

export default function SearchBar({...props}) {
    const [threadId, setThreadId] = useState<string | null>(null);
    const [searchSuggestions, setSearchSuggestions] = useState<string[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchResults, setSearchResults] = useState<Array<{ role: string, content: string }>>([]);
    const [chatIsLoading, setChatIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const initializeThread = async () => {
            const newThreadId = await createThread();
            setThreadId(newThreadId);
        };
        const getSuggestions = async () => {
            const suggestions = await generateSuggestions();
            setSearchSuggestions(suggestions);
        }
        getSuggestions();
        initializeThread();
    }, []);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(event.target.value);
    };

    const handleSendMessage = async () => {
        if (threadId && searchTerm) {
            setChatIsLoading(true);
            const response = await sendMessage(threadId, searchTerm);
            setSearchResults([...searchResults, {role: 'user', content: searchTerm}, {
                role: 'assistant',
                content: response
            }]);
            setChatIsLoading(false);
            setSearchTerm('');
            scrollToBottom();
        }
    };

    const scrollToBottom = (): void => {
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
    };

    const displaySearchResults = (): JSX.Element[] => {
        return searchResults.map((message, index) => (
            <div
                key={index}
                className={`message-container ${message.role === 'user' ? 'message-user' : 'message-assistant'}`}
            >
                <Markdown className={'markdown'}>{message.content}</Markdown>
            </div>
        ));
    }


    return (
        <div className="search-container">
            { searchResults.length === 0 ?
            <div className="search-suggestion-grid">
                {searchSuggestions
                    .map((suggestion, index) => (
                        <div className="search-suggestion-capsule" key={index}>
                            <h2 onClick={() => setSearchTerm(suggestion)}>{suggestion}</h2>
                        </div>
                    ))}
            </div> : null
            }
            <div className="search-results">
                {displaySearchResults()}
            </div>
            <div className="search-bar-container">
                <input
                    className="search-bar"
                    type="text"
                    placeholder="Ask me a question..."
                    value={searchTerm}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSendMessage();
                        }
                    }}
                    onChange={handleChange}
                />
                {chatIsLoading ? (
                    <div className="loading-spinner">
                        <ThreeCircles
                            visible={true}
                            height="25"
                            width="25"
                            color="#007bff"
                            ariaLabel="three-circles-loading"
                            wrapperStyle={{}}
                            wrapperClass="loading-spinner-wrapper"
                        />
                    </div>
                ) : (
                    <button type={'submit'} className="search-button" onClick={handleSendMessage}>
                        <i className="send-icon"></i>
                    </button>
                )}
            </div>
            <div className={'warning-text'}>
                <p>Warning: I may hallucinate answers here. Please reach me by email for serious inquiries.</p>
            </div>
        </div>
    );

}
