import React from 'react';
import copy from 'clipboard-copy';

import SearchBar from '../../Components/SearchBar/SearchBar';
import './SearchView.css';

function copyEmail() {
    copy('sfbowen4.5@gmail.com');
    alert('Email copied to clipboard!');
}

export default function SearchView() {
    return (
        <div className="search-view">
            <div className={'header'}>
                <h1>
                    Stephen Bowen
                </h1>
                <div className={'socials'}>
                    <a href="https://www.linkedin.com/in/sfbowen4/" target="_blank" rel="noreferrer">
                        <i className="linkedin-icon"></i>
                    </a>
                    <a href="https://github.com/sfbowen4" target="_blank" rel="noreferrer">
                        <i className="github-icon"></i>
                    </a>
                    <div onClick={() => copyEmail()}>
                        <i className="mail-icon"></i>
                    </div>
                </div>
            </div>
             <SearchBar/>
        </div>
    );
}

